<!--
data: 
- title: text
- subTitle: text
- text: text
- list: list (icon_image: image, title: text, text: text, link: text)
- bgText: text
- backgroundImage: image
- cards: list (title: text, text: text, linkText: text, linkUrl: text, image: image, number: number)
- buttons: list (buttonText: text, url: text)
- style: select (light: text, dark: text, color: text)
-->

<template>
	<div class="cs-block cs-promo-panel-block" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<div class="header-content">
					<h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
					<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
					<span class="cs-text" v-if="data.text" v-html="data.text"></span>
				</div>
				<div class="row">
					<div v-for="(item, key) in data.cards" :key="key" class="col-lg-4">
						<CSButton :data="item.url">
							<img v-if="item.image" :src="item.image" />
							<h4 v-if="item.number || item.title">
								<template v-if="item.number">{{ item.number }}.</template>
								<template v-if="item.title">{{ item.title }}</template>
							</h4>
							<p v-if="item.text">{{ item.text }}</p>
						</CSButton>
					</div>
				</div>
				<div class="btns">
					<Buttons :data="data.buttons" :cs_style="data.style" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "PromoPanel",
	components: {
		Buttons: () => import("../components/CSUtilsButtons.vue"),
		CSButton: () => import("../components/CSButton.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {}
	},
	computed: computed('PromoPanel')
}
</script>
<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-promo-panel-block {
    &.cs-style-dark {
        ::v-deep .cs-button-container .cs-button {
            background: #e0ecb8;
            &:hover {
                background-color: $theme_color;
                h4,
                p {
                    color: $secondary_text_color !important;
                }
            }
        }
    }
	.header-content {
		text-align: center;
		margin-bottom: 10px;
	}
	::v-deep .cs-button-container .cs-button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #f5f5f5;
		color: $primary_text_color;
		padding: 30px;
		text-decoration: none;
		margin-bottom: 30px;
		height: calc(100% - 30px);
		@include base-transition;
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}
		&:hover {
			background-color: $bg_dark;
			h4,
			p {
				color: $text_color_over_theme_color !important;
			}
		}
		img {
			height: 100px;
			object-fit: contain;
			object-position: center;
			margin-bottom: 20px;
		}
		h4 {
			@include base-transition;
			@include small-title;
			font-size: 18px;
		}
		p {
			@include base-transition;
			@include text;
		}
	}
	.btns {
		text-align: center;
        ::v-deep .button {
			@media (max-width: 500px) {
				justify-content: center !important;
			}
		}
	}
}
</style>
